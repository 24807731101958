.image-area {
  max-width: 512px;
  width: 100%;
  height: auto;
  position: relative;
  min-height: 280px;
}

.gtm-image-area {
  max-width: 512px;
  width: 100%;
  height: auto;
  position: relative;
  min-height: 14.5rem;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #0f172a;
}

* {
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
}

h1 {
  margin: 0;
  padding: 0;
}

a {
  color: #eee;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App {
  max-width: 512px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-mini-heading {
  font-size: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.subscribe-on-kofi-btn {
  background: rgb(32, 135, 172);
  border-radius: 5px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 20px;
  border: 0;
  padding: 7px;
  color: #eee;
  white-space: nowrap;
}

.guess-hint {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-right-radius: 5px;
  color: #eee;
  font-weight: bold;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5px 10px 7px 10px;
}

.chart-container {
  height: 300px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-self: center;
}

@media screen and (max-width: 284px) {
  html body #root .App .PlayArea .input-area .game-input span {
    font-size: 16px;
  }
}
@media screen and (max-width: 300px) {
  html body #root .App .PlayArea .guess-result {
    font-size: 16px;
    height: 29px;
  }
  html body #root .App .PlayArea .guess-result .guess-result-franchise {
    font-size: 12px;
  }
  .kufi-link,
  .miss-a-day-link,
  .submit-link {
    padding: 0 10px;
  }
}
@media screen and (max-width: 320px) {
  .chart-container {
    height: 155px;
  }
  .modal-mini-heading {
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
  }
  html body #root .App .Header h1.Logo {
    font-size: 16px;
    position: relative;
  }
}
@media screen and (max-width: 420px) {
  .guess-hint {
    font-size: 12px;
    padding: 4px;
  }
  html body #root .App .Header h1.Logo {
    font-size: 17px;
    margin-top: 5px;
  }
  html body #root .App .Header h1.Logo span {
    display: none;
  }
  html body #root .App .Header {
    margin-bottom: 2px;
  }
  html body #root .App .Header {
    padding: 0 0 0 5px;
  }
}
@media screen and (max-width: 420px) {
  .PlayArea div.input-area {
    display: flex;
    flex-direction: row;
  }
  .play-area-btns button {
    width: 50%;
    margin: 5px !important;
  }
}
@media screen and (max-width: 520px) {
  html body #root .App .PlayArea {
    padding: 0 10px;
  }
  .countdownControls {
    padding: 0 10px;
  }
}
.game-info {
  padding: 0 10px;
}

.submitted-by {
  max-width: 400px;
}

.modal-bg {
  z-index: 9999;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  color: #eee;
  border-radius: 5px;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  width: 300px;
  margin: 0 auto;
  padding: 12px;
  background-color: rgb(51, 65, 85);
  border: 1px solid #eee;
  z-index: 99999;
}

.submit-link {
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

.about-modal {
  width: auto !important;
  max-width: 500px;
}

@media screen and (max-width: 530px) {
  .about-modal {
    margin: 20px;
  }
  .howtoplay-modal {
    margin: 20px;
  }
  .stats-modal {
    margin: 20px;
  }
  .love-modal {
    margin: 20px;
  }
  .inter-ad-modal {
    margin: 20px;
  }
  .kufi-right {
    visibility: hidden;
  }
}
.howtoplay-modal {
  width: auto !important;
  max-width: 500px;
}

.stats-modal {
  width: auto !important;
  max-width: 500px;
  height: auto;
}

.modal h3,
.modal p {
  padding: 0;
  margin: 0;
}

.modal h3 {
  margin-bottom: 10px;
}

.play-area-btns {
  display: flex;
  flex-direction: row;
}

.footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.guesses-remaining {
  padding: 0;
  color: #eee;
  text-align: center;
  margin: 5px 0 0;
}

.modal p {
  margin-bottom: 20px;
}

.modal-inner {
  position: relative;
}

.modal p a {
  color: white;
}

.modal .close-modal {
  background: none;
  border: 0;
  color: white;
  position: absolute;
  right: 0;
  top: 10px;
}

.love-modal {
  width: auto !important;
  max-width: 500px;
}

.redirect-modal {
  width: auto !important;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.redirect-modal .redirectButton {
  height: 36px;
  width: 160px;
  background-color: rgb(5, 150, 105);
  font-weight: 700;
  font-size: 14px;
}

.modal .login-button {
  background-color: rgb(6, 78, 59);
  font-size: 18px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}

.modal .email-input {
  font-size: 18px;
  width: 100%;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.inter-ad-modal {
  width: auto !important;
  /* max-width: 500px; */
  max-width: 90 dvw;
  max-height: 90 dvh;
  height: auto;
}

button {
  cursor: pointer;
}

svg {
  transition: opacity 150ms ease;
  opacity: 0.8;
}

svg:hover {
  opacity: 1;
}

.Header {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Header button {
  background: none;
  border: 0;
  color: #eee;
  align-self: flex-end;
  padding: 1px;
}
.Header button svg {
  height: 28px;
}

.Logo {
  color: #eee;
  font-size: 28px;
}

.Gtmlogo {
  font-size: 24px;
}

.love-btn {
  margin-left: auto;
}

.stats-section {
  text-align: center;
}

.Screenshots {
  padding: 0px 8px 0px 8px;
}

.image-area .gtl-image {
  max-width: 100%;
  height: auto;
}
.image-area div.game-image {
  border-radius: 5px;
  width: 100%;
  max-width: 512px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  height: 280px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gtm-image-area div.game-image {
  border-radius: 5px;
  width: 100%;
  max-width: 512px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: black;
}
.gtm-image-area img {
  object-fit: contain;
}

@media screen and (max-width: 440px) {
  .image-area {
    min-height: 200px !important;
  }
  .gtm-image-area {
    min-height: 12rem !important;
  }
  @supports (aspect-ratio: calc(512 / 280)) {
    .image-area div.game-image {
      aspect-ratio: 1.8285714286;
      height: 100%;
    }
  }
  @supports not (aspect-ratio: calc(512 / 280)) {
    .image-area div.game-image {
      height: 200px !important;
    }
  }
}
@media screen and (max-width: 330px) {
  .image-area {
    min-height: 160px !important;
  }
  .gtm-image-area {
    min-height: 9.5rem !important;
  }
  @supports (aspect-ratio: calc(512 / 280)) {
    .image-area div.game-image {
      aspect-ratio: 1.8285714286;
      height: 100%;
    }
  }
  @supports not (aspect-ratio: calc(512 / 280)) {
    .image-area div.game-image {
      height: 160px !important;
    }
  }
}
/*.image-area img:first-of-type {*/
/*  position: relative !important;*/
/*}*/
.image-selector {
  margin-top: 10px;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.image-selector .gamecube {
  border: 0;
  height: 36px;
  width: 26px;
  margin: 5px;
  border-radius: 4px;
  font-size: 20px;
  color: #eee;
  opacity: 0.8;
  transition: opacity 150ms ease;
  background-color: rgb(30, 41, 59);
  text-align: center;
  padding: 0;
}

.image-selector button:hover {
  opacity: 1;
}

.image-selector button.locked {
  opacity: 0.3;
  cursor: not-allowed;
}

.image-selector button.active {
  background-color: rgb(71, 85, 105);
  font-weight: 900;
}

.PlayArea {
  margin-top: 15px;
}

.input-area {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

.MuiOutlinedInput-root {
  border: 1px solid #eee;
  background-color: #eee;
}

.input-area input:focus {
  outline: none !important;
}

/*freestar */
/*for the side vertical ads make it so that they only display on a certain width or greater*/
@media screen and (max-width: 1460px) {
  .fs-sidewall-container {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #guessthe-video {
    display: none !important;
  }
  .bl-a-right {
    display: none !important;
  }
}
@media screen and (max-width: 820px) {
  #fs-slot-footer-wrapper .__fs-branding {
    top: -32px !important;
    left: 0 !important;
  }
}
.__fs-branding {
  display: none !important;
}

/* end freestar */
.input-area {
  position: relative;
}

.input-area input {
  border: solid 1px #eee;
  border-radius: 5px;
  width: 100%;
  padding-left: 10px;
  font-size: 20px;
  height: 36px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions-scrollable-headsup {
  position: absolute;
  bottom: 5px;
  right: -5px;
  height: 50px;
  width: 50px;
  pointer-events: none;
}

.suggestions-label {
  position: absolute;
  top: 39px;
  text-align: left !important;
  margin: 0;
  padding: 0;
  font-weight: bold;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 40px;
  padding: 5px 5px 5px 8px;
  text-decoration: underline;
  max-width: 420px;
  color: gray !important;
}

.suggestions {
  border-radius: 5px;
  /* border: 1px solid #999; */
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0;
  width: 100%;
  z-index: 8000;
  max-width: 420px;
  position: absolute;
  top: 64px;
  left: 0px;
  background-color: white;
  /* border-top-left-radius: 0;
  border-top-right-radius: 0; */
}

.suggestions li {
  padding: 0.5rem;
  opacity: 1;
  background-color: white;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #eee;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.input-area .play-area-submit,
.input-area .play-area-skip {
  height: 36px;
  border: 0;
  padding: 0 10px;
  margin-left: 10px;
  color: #eee;
  border-radius: 5px;
  background-color: rgb(6, 78, 59);
  font-size: 20px;
  white-space: nowrap;
}

.mainButton {
  border: 0;
  padding: 0 10px;
  color: #eee;
  border-radius: 5px;
  font-size: 20px;
  white-space: nowrap;
}

.submitButton {
  height: 36px;
  width: 100%;
  background-color: rgb(5, 150, 105);
  margin-bottom: 10px;
}

.iconButton {
  height: 36px;
  margin-top: 5px;
  background-color: rgb(32, 135, 172);
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.skipButton {
  background-color: rgb(5, 150, 105);
}

.input-area .play-area-submit:hover {
  background-color: rgba(6, 78, 59, 0.8);
}

.input-area .play-area-submit:active {
  background-color: rgba(6, 78, 59, 0.6);
}

.input-area .play-area-skip {
  background-color: rgb(47, 79, 79);
}

.input-area .play-area-skip:hover {
  background-color: rgba(47, 79, 79, 0.8);
}

.input-area .play-area-skip:active {
  background-color: rgba(47, 79, 79, 0.6);
}

.PlayArea .guess-result {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.75);
  height: 36px;
  border-radius: 5px;
  margin-bottom: 5px;
  color: #eee;
  font-size: 20px;
  white-space: nowrap;
  padding: 3px 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  /* line-height: 32px; */
}

.guess-result .guess-result-franchise {
  font-size: 14px;
}

.PlayArea p {
  margin: 0;
  width: 100%;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
}

.shakeme {
  animation: shake 0.5s;
}

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result h2,
.result h3 {
  color: #eee;
  margin: 0;
  padding: 0;
  animation: shake 0.5s;
  margin-bottom: 10px;
}

.result .answer {
  display: inline-block;
  color: rgb(5, 150, 105);
}

.result h2.win-msg {
  color: rgb(5, 150, 105);
  margin-top: 5px;
  font-size: 36px;
  text-align: center;
}

.result h2.lose-msg {
  color: rgb(185, 28, 28);
  margin-top: 5px;
  font-size: 23px;
  text-align: center;
}

.emoji-track {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.emoji-track .guess-cube {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: rgb(100, 116, 139);
  margin: 5px;
}

.emoji-track .guess-cube.success {
  background-color: rgb(5, 150, 105);
}

.emoji-track .guess-cube.fail {
  background-color: rgb(185, 28, 28);
}

.emoji-track .guess-cube.partial {
  background-color: rgb(241, 198, 78);
}

.stats-modal .buttons-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.stats-modal .clipboardButton {
  height: 36px;
  width: 160px;
  background-color: rgb(5, 150, 105);
  font-weight: 700;
  font-size: 14px;
  text-align: left;
}

.stats-modal .quests-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.stats-modal .quest-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  padding: 8px;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s ease;
}

.stats-modal .quest-item-title {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  /* max-width: 240px; */
  text-align: center;
}

.stats-modal .quest-item:hover .quest-item-title {
  text-decoration: underline;
}

.stats-modal .quest-item .quest-item-image {
  height: 120px;
  aspect-ratio: 1;
  /* width: 100%; */
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease;
  align-self: center;
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.75);
}

.stats-modal .quest-item:hover .quest-item-image {
  opacity: 0.7;
}

.stats-modal .stat {
  text-align: center;
  display: inline-block;
  padding: 5px;
  margin: 0 9px;
}

.stat-value {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.stat-label {
  font-size: 14px;
  color: #eee;
}

.end-of-game-guesses-summary {
  color: #eee;
}

.guess-distribution {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.guess-distribution-row {
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-bottom: 5px;
}

.guess-distribution-row p {
  margin-right: 10px;
}

.guess-distribution-row .guess-row-bar {
  text-align: center;
  background-color: #0f172a;
  padding: 0 5px;
}

.share-results-btn {
  background-color: rgb(5, 150, 105);
  font-size: 24px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  transition: opacity 100ms ease;
}

.share-results-btn:hover,
.play-prev-day-btn {
  opacity: 0.8;
}

.share-results-btn:active,
.play-prev-day-btn {
  opacity: 0.6;
}

.play-prev-day-btn {
  background-color: rgb(32, 135, 172);
  font-size: 18px;
  border: 0;
  border-radius: 5px;
  color: #eee;
  padding: 2px 6px;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: opacity 100ms ease;
  position: relative;
  top: -13px;
  white-space: nowrap;
  text-overflow: clip;
  width: 136px;
}

.play-prev-day-btn:hover {
  opacity: 0.8;
}

.play-prev-day-btn:active {
  opacity: 0.6;
}

.countdown-label {
  margin: 0;
  padding: 0;
  margin-top: 0;
  color: #eee;
}

.socials-label {
  text-decoration: none;
  padding: 10px;
  font-size: 24px;
}

.end-of-game-guesses-summary:last-of-type {
  margin-bottom: 10px;
}

.countdown-to-next-game {
  color: #eee;
  font-size: 24px;
}

.end-of-game-guesses-summary-label {
  color: #eee;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

p.kufi-link {
  text-align: center;
  font-size: 12px;
  color: #eee;
}

p.kufi-link a a {
  color: #eee;
}

.miss-a-day-link {
  margin: 0;
  padding: 0;
}

.previous-games-header,
.prev-game-result-row {
  display: flex;
  flex-direction: row;
  color: #eee;
}

.previous-games .back-to-today {
  width: 100%;
  text-align: center;
}

.previous-games .back-to-today button {
  background-color: rgb(32, 135, 172);
  font-size: 20px;
}

.prev-game-result-row {
  margin-bottom: 3px;
  height: 27px;
  padding-top: 2px;
}

.prev-game-result {
  text-align: center;
}

.prev-game-result.success {
  display: flex;
}

.result-text-supplementary {
  width: 110px;
}

@media screen and (max-width: 550px) {
  .previous-games .prev-game-legend {
    flex-direction: column;
  }
  .prev-game-result-row {
    /* flex-direction: column; */
    /* height: auto; */
  }
  .emoji-track.prev-game-emoji-track {
    position: relative;
    top: -2px !important;
  }
  .prev-game-day-number {
    width: 40px !important;
    position: relative;
    top: -2px !important;
  }
  .prev-game-emoji-track {
    width: 130px !important;
  }
  .guess-cube {
    margin: 1px !important;
  }
  .unplayed-qmark {
    margin: 0 1px !important;
    top: 2px !important;
  }
  .result-text-supplementary {
    display: none;
  }
  .day-text {
    display: none;
  }
  .prev-game-result {
    width: 23px !important;
  }
  .prev-game-result.success,
  .prev-game-result.fail {
    position: relative;
    top: -2px !important;
  }
  .prev-game-result.pending {
    position: relative;
    /* left: 2px !important; */
  }
  .play-prev-game-btn {
    width: 115px;
    position: relative;
  }
  .play-prev-game-btn button {
    width: 115px;
    position: absolute;
    right: 0;
  }
}
.previous-games-header {
  margin-bottom: 10px;
}

.previous-games-header .day-result {
  width: 310px !important;
  text-align: center;
}

.prev-game-legend {
  color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.unplayed-qmark {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid rgb(100, 116, 139);
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  margin: 5px;
}

.prev-game-legend span {
  position: relative;
  top: 4px;
}

.qmark-emojitrack {
  position: relative;
}

.prev-game-legend .emoji-track {
  margin-right: 15px;
}

.previous-games-header .day-number,
.prev-game-result-row .prev-game-day-number {
  width: 50px;
}

.prev-game-result-row {
  cursor: pointer;
  padding-top: 4px;
  transition: background-color 300ms ease;
  justify-content: center;
}

.prev-game-result-row:hover {
  background-color: #2c447d;
}

.previous-games-header .day-emojis,
.prev-game-result-row .prev-game-emoji-track {
  position: relative;
  top: -5px;
}

.previous-games-header .day-result,
.prev-game-result-row .prev-game-result {
  width: 150px;
  position: relative;
}

.footer {
  margin-bottom: 400px;
}

@media (max-width: 767px) {
  .footer {
    margin-bottom: 90px;
  }
}
.share-icon {
  width: 24px;
  padding-top: 5px;
  padding-right: 5px;
}

.game-info {
  color: #eee;
  font-size: 14px;
}

.logo-link {
  text-decoration: none;
  color: #eee;
}

.linkaff-list {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  list-style-type: none;
}

.guess-image-success {
  background-color: rgb(5, 150, 105) !important;
}

.guess-image-fail {
  background-color: rgb(186, 28, 28) !important;
}

sup {
  font-size: 16px;
}

.dropdown-icon {
  vertical-align: middle;
  transition: transform 0.3s ease-in-out;
}

.dropdown-icon.rotated {
  transform: rotate(180deg);
}

.control-emoji {
  font-size: 25px;
}

.control-emoji,
.right {
  margin-left: 25px;
}

.control-emoji,
.left {
  margin-right: 25px;
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?9enp3k");
  src: url("fonts/icomoon.eot?9enp3k#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?9enp3k") format("truetype"), url("fonts/icomoon.woff?9enp3k") format("woff"), url("fonts/icomoon.svg?9enp3k#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-backward2:before {
  content: "\ea1f";
}

.icon-forward3:before {
  content: "\ea20";
}

.icon-amazon:before {
  content: "\ea87";
}

.icon-facebook:before {
  content: "\ea90";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-twitter:before {
  content: "\ea96";
}

.icon-pencil2:before {
  content: "\e906";
}

.icon-rss2:before {
  content: "\ea9c";
}

.icon-files-empty:before {
  content: "\e925";
}

.icon-youtube:before {
  content: "\ea9d";
}

.marquee-container:hover {
  color: white !important;
}

@keyframes shakeAndChangeColorAndReset {
  0%, 25%, 50%, 75% {
    transform: translate(1px, 1px) rotate(0deg);
    background-color: #ff0000;
  }
  5%, 30%, 55%, 80% {
    transform: translate(-1px, -2px) rotate(-1deg);
    background-color: #00ff00;
  }
  10%, 35%, 60%, 85% {
    transform: translate(-3px, 0px) rotate(1deg);
    background-color: #0000ff;
  }
  15%, 40%, 65%, 90% {
    transform: translate(3px, 2px) rotate(0deg);
    background-color: #ffff00;
  }
  20%, 45%, 70% {
    transform: translate(1px, -1px) rotate(1deg);
    background-color: #ff00ff;
  }
  100% {
    transform: none;
    /*background-color: initial;*/
  }
}
.shake-and-change-color {
  animation: shakeAndChangeColorAndReset 7s linear forwards;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.twitter-tweet {
  max-width: 1000px !important;
}

/* kufi stuff moves it to the bottom right corner
            hide if on mobile */
img.kufiimg {
  display: initial !important;
  vertical-align: middle;
  height: 13px !important;
  width: 20px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none;
  margin-top: 0;
  margin-right: 5px !important;
  margin-left: 0 !important;
  margin-bottom: 3px !important;
  content: url("https://storage.ko-fi.com/cdn/cup-border.png");
}

.kufiimg:after {
  vertical-align: middle;
  height: 25px;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  margin-top: 0;
  margin-right: 6px;
  margin-left: 0;
  margin-bottom: 4px !important;
  content: url("https://storage.ko-fi.com/cdn/whitelogo.svg");
}

.btn-container {
  display: inline-block !important;
  white-space: nowrap;
  min-width: 160px;
}

span.kufitext {
  color: #fff !important;
  letter-spacing: -0.15px !important;
  text-wrap: none;
  vertical-align: middle;
  line-height: 33px !important;
  padding: 0;
  text-align: center;
  text-decoration: none !important;
  text-shadow: 0 1px 1px rgba(34, 34, 34, 0.05);
}

.kufitext a {
  color: #fff !important;
  text-decoration: none !important;
}

.kufitext a:hover {
  color: #fff !important;
  text-decoration: none;
}

a.kufi-button {
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  line-height: 36px !important;
  min-width: 150px;
  display: inline-block !important;
  background-color: #29abe0;
  padding: 0 12px !important;
  text-align: center !important;
  border-radius: 7px;
  color: #fff;
  cursor: pointer;
  overflow-wrap: break-word;
  vertical-align: middle;
  border: 0 none #fff !important;
  text-decoration: none;
  text-shadow: none;
  font-weight: 700 !important;
  font-size: 14px !important;
}

a.kufi-button:visited {
  color: #fff !important;
  text-decoration: none !important;
}

a.kufi-button:hover {
  opacity: 0.85;
  color: #f5f5f5 !important;
  text-decoration: none !important;
}

a.kufi-button:active {
  color: #f5f5f5 !important;
  text-decoration: none !important;
}

.kufitext img.kufiimg {
  height: 15px !important;
  width: 22px !important;
  display: initial;
  animation: kufi-wiggle 3s infinite;
}

@keyframes kufi-wiggle {
  0% {
    transform: rotate(0) scale(1);
  }
  60% {
    transform: rotate(0) scale(1);
  }
  75% {
    transform: rotate(0) scale(1.12);
  }
  80% {
    transform: rotate(0) scale(1.1);
  }
  84% {
    transform: rotate(-10deg) scale(1.1);
  }
  88% {
    transform: rotate(10deg) scale(1.1);
  }
  92% {
    transform: rotate(-10deg) scale(1.1);
  }
  96% {
    transform: rotate(10deg) scale(1.1);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.kufi-right {
  animation: fadeIn 5s;
  position: fixed;
  bottom: 10px;
  right: 10px;
  min-width: 0 !important;
}

.kufi-center {
  /*width: 161px;*/
  animation: fadeIn 5s;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.export-btn {
  font-size: 13px;
}

.loadingStyle {
  text-align: center;
  color: #eee;
  opacity: 50%;
  padding-bottom: 10px;
  font-style: oblique;
  -webkit-animation: breathing 2s ease-out infinite normal;
  animation: breathing 2s ease-out infinite normal;
}

.bl-a {
  animation: fadeIn 5s;
}

.frosted {
  filter: blur(1px);
  -webkit-filter: blur(1px); /*chrome (android), safari (ios), opera*/
}

.hint-source-link {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 5px;
  color: #eee;
  font-style: oblique;
  font-weight: bold;
  font-size: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5px 10px 5px 10px;
}

.copied {
  position: relative;
  display: inline-block;
}

.copied::after {
  content: "Copied!";
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  background: rgb(5, 150, 105);
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  animation: fadeOut 4s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*gtgq*/
.quest-progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #eee;
}

.quest-progress {
  color: #eee;
  padding: 0px 4px 0px 4px;
  width: 100%;
}

.quests-title {
  font-size: 24px;
  background: #FFFFFF;
  background: linear-gradient(to right, #FFFFFF 0%, #C9C9C9 100%);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quests-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #eee;
  padding: 0px 4px 0px 4px;
  background-color: #141f38;
  border: 1px solid #525252;
  border-radius: 0.5em;
  padding: 2px;
}

.quests-container .quests-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

@media screen and (max-width: 420px) {
  .quests-container .quests-list {
    flex-direction: column;
  }
}
.quests-container .quests-list .quest-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  border: 1px solid #525252;
  border-radius: 0.5em;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  transition: border-color 0.3s ease;
  background-color: #1d2942;
  width: 240px;
}

.quests-container .quests-list .quest-item:hover {
  border: 1px solid #b8b8b8;
}

.quests-container .quest-item .quest-item-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.quests-container .quest-item:hover .quest-item-title {
  text-decoration: underline;
}

.quests-container .quest-item .quest-item-description {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.quests-container .quest-item .quest-item-image {
  min-height: 100px;
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
  align-self: center;
}

.quests-container .quest-item:hover .quest-item-image {
  transform: scale(1.05);
}

.quests-container .quest-item .quest-item-button {
  width: 100%;
  font-size: 10px;
  font-weight: 600;
}

.questScoreButton {
  height: 36px;
  width: 160px;
  background-color: rgb(5, 150, 105);
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}

/*gta
 */
.rhap_container {
  border-radius: 5px;
}

.privacyButton {
  color: white;
  cursor: pointer;
  background: transparent;
  border: none;
  text-decoration: underline;
}
.privacyButton :hover {
  visibility: visible;
  color: grey;
}

.image-cover-control {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.image-cover-control .slider {
  accent-color: #dbdbdb;
  color: white;
}

.tab-indicator {
  background-color: #05a0a0;
  height: 3px;
  margin-top: 3px;
}

